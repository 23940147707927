@font-face {
  font-family: 'lora';
  src: url('fonts/lora/static/Lora-Regular.ttf'),
  /* Add additional font formats if necessary */
  /* Provide the correct path to the font files */
}
@font-face {
  font-family: 'biotif';
  src: url('fonts/Biotif/Fontspring-DEMO-biotif-regular.otf'),
  /* Add additional font formats if necessary */
  /* Provide the correct path to the font files */
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




body{
  font-size: 0.9rem !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 0;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #ff7400;  */
  background: var(--primary-color-stock); 
  border-radius: 0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000; 
}

.btn-primary{
  /* background-color: #ff7400 !important; */
  background-color: var(--primary-color-stock) !important;
  border: none;
}
.rdp {
  --rdp-cell-size: 40px;
  --rdp-accent-color: var(--primary-color-stock);
  --rdp-background-color: var(--primary-color-stock);
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: #3003e1;
  /* Outline border for focused elements */
  --rdp-outline: 2px solid var(--rdp-accent-color);
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
}
button#bg-nested-dropdown{
  background-color: #FFF !important;
  color: var(--primary-color-stock);
}
button#bg-nested-dropdown:hover{
  background-color: #ff00001a !important;
}
.dropdown-toggle::after{
  display: none;
}
.table-responsive{
  /* min-height: 320px; */
}

.css-dip3t8{
  background: #f9f9f9 !important;
}